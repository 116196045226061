@import (reference) "~@zenlayer/zen-design/esm/style/index.less";
a {
  color: var(--zen-design-primary-high-color);
  text-decoration: none;
}

body {
  color: var(--zen-design-onSurface-fontHigh-color);
  font-family: var(--zen-design-font-family);
  font-size: 14px;
}
